import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class FranchiseService {

  //private order: OrderAppModel;

  constructor(private http: HttpClient) {
  
  }

  public getAll(): Observable<any> {   
    console.log("getAll",environment.serverUrl);
    return this.http.get(environment.serverUrl + 'Franchise/GetFranchisesNew');
  }

  public getAllNew(): Observable<any> {   
    console.log("getAll",environment.serverUrl);
    return this.http.get(environment.serverUrl + 'Franchise/GetFranchisesNew');
  }
  
  public delete(franchiseId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Franchise/DeleteFranchise', 
      {
        id: franchiseId
      });
  }

  public getFranchise(franchiseId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Franchise/GetFranchise?id=' + franchiseId);
  }

  public createFranchise(franchise): Observable<any> {
    var fd = new FormData();
      fd.append("Name", franchise.Name);
      fd.append("IsUseCashRegister", franchise.IsUseCashRegister);
      fd.append("AndroidName", franchise.AndroidName);
      fd.append("AppleName", franchise.AppleName);
      fd.append("IsFutureOrderAvailable", franchise.IsFutureOrderAvailable);
      fd.append("IsFutureDatesOrderAvailable", franchise.IsFutureDatesOrderAvailable);

      if (franchise.FcmServerApiKey != undefined) fd.append("FcmServerApiKey", franchise.FcmServerApiKey);
      if (franchise.FcmSenderId != undefined) fd.append("FcmSenderId", franchise.FcmSenderId);
      if (franchise.StartingPage != undefined) fd.append("StartingPage", franchise.StartingPage);
      if (franchise.minimumVersion != undefined) fd.append("minimumVersion", franchise.minimumVersion);
      if (franchise.ManagerName != undefined) fd.append("ManagerName", franchise.ManagerName);
      if (franchise.ManagerPhone != undefined) fd.append("ManagerPhone", franchise.ManagerPhone);
      if (franchise.ManagerMobile != undefined) fd.append("ManagerMobile", franchise.ManagerMobile);
      if (franchise.ManagerEmail != undefined) fd.append("ManagerEmail", franchise.ManagerEmail);
      if (franchise.FacebookLink != undefined) fd.append("FacebookLink", franchise.FacebookLink);
      if (franchise.InstagramLink != undefined) fd.append("InstagramLink", franchise.InstagramLink);
      if (franchise.AppInstallationLink != undefined) fd.append("AppInstallationLink", franchise.AppInstallationLink);
      // if(franchise.AndroidInstallationLink != undefined) fd.append("AndroidInstallationLink", franchise.AndroidInstallationLink);
      fd.append("ImageFile", franchise.ImageFile);
      fd.append("CoverImageFile", franchise.CoverImageFile);
      fd.append("PizzaCategoryImageFile", franchise.PizzaCategoryImageFile);
      fd.append("ComboCategoryImageFile", franchise.ComboCategoryImageFile);
      fd.append("AboutCoverImgFile", franchise.AboutCoverImgFile);
      if (franchise.ButtonColor != undefined) fd.append("ButtonColor", franchise.ButtonColor);
      if (franchise.MainButtonColor != undefined) fd.append("MainButtonColor", franchise.MainButtonColor);
      if (franchise.MenuColor != undefined) fd.append("MenuColor", franchise.MenuColor);

      if (franchise.CategoryColor != undefined) fd.append("CategoryColor", franchise.CategoryColor);
      if (franchise.PriceColor != undefined) fd.append("PriceColor", franchise.PriceColor);
      if (franchise.DaysToSendSurvey != undefined) fd.append("DaysToSendSurvey", franchise.DaysToSendSurvey);
      if (franchise.HasSurveysModule != undefined) fd.append("HasSurveysModule", franchise.HasSurveysModule);

      if (franchise.Description != undefined) fd.append("Description", franchise.Description);
      if (franchise.Cash != undefined) fd.append("Cash", franchise.Cash);
      if (franchise.CreditCard != undefined) fd.append("CreditCard", franchise.CreditCard);
      if (franchise.CreditPhone != undefined) fd.append("CreditPhone", franchise.CreditPhone);
      if (franchise.CustomPayment != undefined) fd.append("CustomPayment", franchise.CustomPayment);
      if (franchise.IsCustomPayment != undefined) fd.append("IsCustomPayment", franchise.IsCustomPayment);
      if (franchise.IsShowInHalfs != undefined) fd.append("IsShowInHalfs", franchise.IsShowInHalfs);
      if (franchise.IsPrintInList != undefined) fd.append("IsPrintInList", franchise.IsPrintInList);

      if (franchise.IFrameConfigSettings != undefined) fd.append("IFrameConfigSettings", franchise.IFrameConfigSettings);
      if (franchise.IFrameLoginCoverImageFile != undefined) fd.append("IFrameLoginCoverImageFile", franchise.IFrameLoginCoverImageFile);
      if (franchise.IFrameCoverImageFile != undefined) fd.append("IFrameCoverImageFile", franchise.IFrameCoverImageFile);
      if (franchise.IFrameLoginMobileCoverImageFile != undefined) fd.append("IFrameLoginMobileCoverImageFile", franchise.IFrameLoginMobileCoverImageFile);
      if (franchise.IFrameCoverMobileImageFile != undefined) fd.append("IFrameCoverMobileImageFile", franchise.IFrameCoverMobileImageFile);
      
      if (franchise.TranzilaTokenTerminal != undefined) fd.append("TranzilaTokenTerminal", franchise.TranzilaTokenTerminal);
      if (franchise.PrintMsg) fd.append("PrintMsg", franchise.PrintMsg);
      fd.append("TranzilaUrl", franchise.TranzilaUrl);
      fd.append("TranzilaPW", franchise.TranzilaPW);
      fd.append("TranzilaTokenFallback", franchise.TranzilaTokenFallback);
      fd.append("TranzilaTokenFallbackPW", franchise.TranzilaTokenFallbackPW);
      fd.append("UseTranzilaUrlExpress", franchise.UseTranzilaUrlExpress);

      //discount-coupon
      if (franchise.DiscountEndStr != undefined) fd.append("DiscountEndStr", franchise.DiscountEndStr.toLocaleString("pt"));
      if (franchise.DiscountSum != undefined) fd.append("DiscountSum", franchise.DiscountSum);
      if (franchise.DiscountName != undefined) fd.append("DiscountName", franchise.DiscountName);
      if (franchise.DiscountTypeStr != undefined) fd.append("DiscountTypeStr", franchise.DiscountTypeStr);
      if (franchise.MinSumForDiscount != undefined) fd.append("MinSumForDiscount", franchise.MinSumForDiscount);

      if (franchise.GalleryFiles != undefined) {
        franchise.GalleryFiles.forEach(function (file, index) {
          fd.append("GalleryFiles[" + index + "]", file);
        });
      }

      // Sms response:
      if (franchise.SmsResponse != undefined) fd.append("SmsResponse", franchise.SmsResponse);

    return this.http.post<any>(environment.serverUrl +
      'Franchise/CreateFranchise', fd );
  }

  public updateFranchise(franchise): Observable<any> {
    var fd = new FormData();
      fd.append("Id", franchise.Id);
      fd.append("Name", franchise.Name);
      fd.append("IsUseCashRegister", franchise.IsUseCashRegister);
      fd.append("AndroidName", franchise.AndroidName);
      fd.append("AppleName", franchise.AppleName);
      fd.append("IsFutureOrderAvailable", franchise.IsFutureOrderAvailable);
      fd.append("IsFutureDatesOrderAvailable", franchise.IsFutureDatesOrderAvailable);
      fd.append("UseMembersClub", franchise.UseMembersClub);
      fd.append("PoinsPercentage", franchise.PoinsPercentage);
      fd.append("MinSumForVouchers", franchise.MinSumForVouchers);
      fd.append("NewMemberVoucherActive", franchise.NewMemberVoucherActive);
      fd.append("BirthdayVoucherActive", franchise.BirthdayVoucherActive);
      fd.append("AnniversaryVoucherActive", franchise.AnniversaryVoucherActive);
      fd.append("BonusActive", franchise.BonusActive);
      fd.append("ScratchCuponActive", franchise.ScratchCuponActive);
      fd.append("OnePlusOneActive", franchise.OnePlusOneActive);
      fd.append("DaysToSendSurvey", franchise.DaysToSendSurvey);
      fd.append("HasSurveysModule", franchise.HasSurveysModule);

      if (franchise.FcmServerApiKey != undefined) fd.append("FcmServerApiKey", franchise.FcmServerApiKey);
      if (franchise.FcmSenderId != undefined) fd.append("FcmSenderId", franchise.FcmSenderId);
      if (franchise.StartingPage != undefined) fd.append("StartingPage", franchise.StartingPage);
      if (franchise.minimumVersion != undefined) fd.append("minimumVersion", franchise.minimumVersion);
      if (franchise.ManagerName != undefined) fd.append("ManagerName", franchise.ManagerName);
      if (franchise.ManagerPhone != undefined) fd.append("ManagerPhone", franchise.ManagerPhone);
      if (franchise.ManagerMobile != undefined) fd.append("ManagerMobile", franchise.ManagerMobile);
      if (franchise.ManagerEmail != undefined) fd.append("ManagerEmail", franchise.ManagerEmail);
      fd.append("ImageFile", franchise.ImageFile);
      if (franchise.FacebookLink != undefined) fd.append("FacebookLink", franchise.FacebookLink);
      if (franchise.InstagramLink != undefined) fd.append("InstagramLink", franchise.InstagramLink);
      if (franchise.AppInstallationLink != undefined) fd.append("AppInstallationLink", franchise.AppInstallationLink);
      // if(franchise.AndroidInstallationLink != undefined) fd.append("AndroidInstallationLink", franchise.AndroidInstallationLink);
      if (franchise.DiscountTypeStr != undefined) fd.append("DiscountTypeStr", franchise.DiscountTypeStr);
      if (franchise.MinSumForDiscount != undefined) fd.append("MinSumForDiscount", franchise.MinSumForDiscount);

      fd.append("CoverImageFile", franchise.CoverImageFile);
      fd.append("PizzaCategoryImageFile", franchise.PizzaCategoryImageFile);
      fd.append("ComboCategoryImageFile", franchise.ComboCategoryImageFile);
      fd.append("PizzaAppCategoryImageFile", franchise.PizzaAppCategoryImageFile);
      fd.append("ComboAppCategoryImageFile", franchise.ComboAppCategoryImageFile);
      fd.append("AboutCoverImgFile", franchise.AboutCoverImgFile);
      if (franchise.ButtonColor != undefined) fd.append("ButtonColor", franchise.ButtonColor);
      if (franchise.MainButtonColor != undefined) fd.append("MainButtonColor", franchise.MainButtonColor);
      if (franchise.MenuColor != undefined) fd.append("MenuColor", franchise.MenuColor);

      if (franchise.CategoryColor != undefined) fd.append("CategoryColor", franchise.CategoryColor);
      if (franchise.PriceColor != undefined) fd.append("PriceColor", franchise.PriceColor);

      if (franchise.Cash != undefined) fd.append("Cash", franchise.Cash);
      if (franchise.CreditCard != undefined) fd.append("CreditCard", franchise.CreditCard);
      if (franchise.CreditPhone != undefined) fd.append("CreditPhone", franchise.CreditPhone);
      if (franchise.CustomPayment != undefined) fd.append("CustomPayment", franchise.CustomPayment);
      if (franchise.IsCustomPayment != undefined) fd.append("IsCustomPayment", franchise.IsCustomPayment);
      if (franchise.IsShowInHalfs != undefined) fd.append("IsShowInHalfs", franchise.IsShowInHalfs);
      if (franchise.IsPrintInList != undefined) fd.append("IsPrintInList", franchise.IsPrintInList);
      if (franchise.Description != undefined) fd.append("Description", franchise.Description);

      if (franchise.IFrameConfigSettings != undefined) fd.append("IFrameConfigSettings", franchise.IFrameConfigSettings);
      if (franchise.IFrameLoginCoverImageFile != undefined) fd.append("IFrameLoginCoverImageFile", franchise.IFrameLoginCoverImageFile);
      if (franchise.IFrameCoverImageFile != undefined) fd.append("IFrameCoverImageFile", franchise.IFrameCoverImageFile);
      if (franchise.IFrameLoginMobileCoverImageFile != undefined) fd.append("IFrameLoginMobileCoverImageFile", franchise.IFrameLoginMobileCoverImageFile);
      if (franchise.IFrameCoverMobileImageFile != undefined) fd.append("IFrameCoverMobileImageFile", franchise.IFrameCoverMobileImageFile);

      if (franchise.TranzilaTokenTerminal != undefined) fd.append("TranzilaTokenTerminal", franchise.TranzilaTokenTerminal);
      if (franchise.PrintMsg) fd.append("PrintMsg", franchise.PrintMsg);
      fd.append("TranzilaUrl", franchise.TranzilaUrl);
      fd.append("TranzilaPW", franchise.TranzilaPW);
      fd.append("TranzilaTokenFallback", franchise.TranzilaTokenFallback);
      fd.append("TranzilaTokenFallbackPW", franchise.TranzilaTokenFallbackPW);
      fd.append("UseTranzilaUrlExpress", franchise.UseTranzilaUrlExpress);

      //discount-coupon
      if (franchise.DiscountEndStr != undefined) fd.append("DiscountEndStr", franchise.DiscountEndStr.toLocaleString("pt"));
      if (franchise.DiscountSum != undefined) fd.append("DiscountSum", franchise.DiscountSum);
      if (franchise.DiscountName != undefined) fd.append("DiscountName", franchise.DiscountName);
      if (franchise.DiscountTypeStr != undefined) fd.append("DiscountTypeStr", franchise.DiscountTypeStr);
      if (franchise.MinSumForDiscount != undefined) fd.append("MinSumForDiscount", franchise.MinSumForDiscount);

      // Sms response:
      if (franchise.SmsResponse != undefined) fd.append("SmsResponse", franchise.SmsResponse);

      if (franchise.GalleryFiles != undefined) {
        franchise.GalleryFiles.forEach(function (file, index) {
          fd.append("GalleryFiles[" + index + "]", file);
        });
      }
      if (franchise.GalleryUrlsToDelete != undefined) {
        franchise.GalleryUrlsToDelete.forEach(function (file, index) {
          fd.append("GalleryUrlsToDelete[" + index + "]", file);
        });
      }

    return this.http.post<any>(environment.serverUrl +
      'Franchise/UpdateFranchise', fd );
  }

  public getFranchiseIframeSettings(franchiseId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Franchise/GetFranchiseIframeSettings?franchiseId=' + franchiseId);
  }

  public createOrUpdateIframeSettings(settings): Observable<any> {
    var fd = new FormData();
      fd.append("Id", settings.Id);
      fd.append("FranchiseId", settings.FranchiseId);
      fd.append("ignoreCupons", settings.ignoreCupons);
      fd.append("displayPopup", settings.displayPopup);
      fd.append("checkItemsByTime", settings.checkItemsByTime);
    //  fd.append("IsFutureOrderAvailable", settings.IsFutureOrderAvailable);
     // fd.append("IsFutureDatesOrderAvailable", settings.IsFutureDatesOrderAvailable);
      fd.append("cancelPhoneVerification", settings.cancelPhoneVerification);
      fd.append("dontDisplayPhonePopup", settings.dontDisplayPhonePopup);
      fd.append("dontUseASAP", settings.dontUseASAP);
      fd.append("displayFranchisePhoneLink", settings.displayFranchisePhoneLink);
      fd.append("displayBranchPhoneLink", settings.displayBranchPhoneLink);
      fd.append("forceEmail", settings.forceEmail);
      fd.append("dontUsePizzaBuilder", settings.dontUsePizzaBuilder);
      fd.append("displayCompanyCode", settings.displayCompanyCode);
      fd.append("deliveryDetailsAtCheckout", settings.deliveryDetailsAtCheckout);
      fd.append("displayFutureDeliveryOptions", settings.displayFutureDeliveryOptions);
      fd.append("futureDeliveryOptions", settings.futureDeliveryOptions);
      fd.append("popupMsg", settings.popupMsg);
      fd.append("minAmountForBonus", settings.minAmountForBonus);
      fd.append("bonusCategory", settings.bonusCategory);
      fd.append("bonusMsg", settings.bonusMsg);
      fd.append("displayPizzaToppingsInComments", settings.displayPizzaToppingsInComments);
      fd.append("facebookPixelId", settings.facebookPixelId);      
      fd.append("googleTagManager", settings.googleTagManager);
      fd.append("isDigitalMenu", settings.isDigitalMenu);
      fd.append("displayLogoOnHomePage", settings.displayLogoOnHomePage);
      fd.append("appDisplayMode", settings.appDisplayMode);
      fd.append("couponCode", settings.couponCode);
      fd.append("couponCodeDiscount", settings.couponCodeDiscount);

      fd.append("dummyDeliveryGroups", settings.dummyDeliveryGroups);
      fd.append("popupItem", settings.popupItem);
      fd.append("hideFutureDeliveryTime", settings.hideFutureDeliveryTime);
      fd.append("hideFutureTATime", settings.hideFutureTATime);
      fd.append("sendInvoice", settings.sendInvoice);
      fd.append("sitBtnTxt", settings.sitBtnTxt);
      fd.append("taBtnTxt", settings.taBtnTxt);
      fd.append("deliveryBtnTxt", settings.deliveryBtnTxt);

    return this.http.post<any>(environment.serverUrl +
      'Franchise/CreateOrUpdateIframeSettings', fd );
  }

  public getFranchisePolicies(franchiseId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Franchise/GetFranchisePolicies?franchiseId=' + franchiseId);
  }

  public CreateOrUpdatePrivacyPolicy(franchiseId, policy): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Franchise/CreateOrUpdatePrivacyPolicy', 
      {
        franchiseId: franchiseId,
        policy: policy
      });
  }

  public CreateOrUpdateMembersClubPolicy(franchiseId, policy): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Franchise/CreateOrUpdateMembersClubPolicy', 
      {
        franchiseId: franchiseId,
        policy: policy
      });
  }

  public CreateOrUpdateTerms(franchiseId, terms): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Franchise/CreateOrUpdateTerms', 
      {
        franchiseId: franchiseId,
        terms: terms
      });
  }

  public CreateOrUpdateInfo(franchiseId, info): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Franchise/CreateOrUpdateInfo', 
      {
        franchiseId: franchiseId,
        info: info
      });
  }

  public CreateOrUpdateMoreInfo(franchiseId, info): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Franchise/CreateOrUpdateMoreInfo', 
      {
        franchiseId: franchiseId,
        info: info
      });
  }

 
  //Translation

  public getCategoriesTranslation(categoryId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/GetCategoriesTranslation?categoryId=' + categoryId);
  }

  public getItemsTranslation(itemId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/GetItemsTranslation?itemId=' + itemId);
  }

  public getGarnishTranslation(garnishId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/GetGarnishTranslation?garnishId=' + garnishId);
  }

  public getGarnishGroupsTranslation(garnishGroupId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/GetGarnishGroupsTranslation?garnishGroupId=' + garnishGroupId);
  }

  public getCombosTranslation(comboId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/GetCombosTranslation?comboId=' + comboId);
  }

  public getItemComboTranslation(id): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/GetItemComboTranslation?id=' + id);
  }

  public getPizzasTranslation(pizzaId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/GetPizzasTranslation?pizzaId=' + pizzaId );
  }

  public getToppingsTranslation(topId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/GetToppingsTranslation?topId=' + topId );
  }


  public getPizzaSizesTranslation(pizzaSizeId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Translation/getPizzaSizesTranslation?pizzaSizeId=' + pizzaSizeId);
  }

  // update translations
  public updateCategoriesTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdateCategoriesTranslation', 
      {
        translation: translation
      });
  }

  public updateItemsTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdateItemsTranslation', 
      {
        translation: translation
      });
  }

  public updateGarnishTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdateGarnishTranslation', 
      {
        translation: translation
      });
  }

  public updateGarnishGroupTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdateGarnishGroupTranslation', 
      {
        translation: translation
      });
  }

  public updateComboTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdateComboTranslation', 
      {
        translation: translation
      });
  }

  public updateItemComboTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdateItemComboTranslation', 
      {
        translation: translation
      });
  }

  public updatePizzaTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdatePizzaTranslation', 
      {
        translation: translation
      });
  }

  public updateToppingTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdateToppingTranslation', 
      {
        translation: translation
      });
  }

  public updatePizzaSizeTranslation(translation): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Translation/UpdatePizzaSizeTranslation', 
      {
        translation: translation
      });
  }


}
