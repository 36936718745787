import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GarnishAppModel } from '../../models/menu/garnish-app.model';

//import {TranslationsService} from "../../shared/translations/translations.service";

@Injectable()
export class GarnishGroupService {

  constructor(private http: HttpClient) {}

  public GetFranchiseGeneralGarnishes(franchiseId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'GarnishGroup/GetFranchiseGeneralGarnishes?franchiseId=' + franchiseId);
  }

  public CreateGeneralGarnish(generalGarnish): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/CreateGeneralGarnish', generalGarnish );
  }

  public UpdateGeneralGarnish(generalGarnish): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/UpdateGeneralGarnish', generalGarnish );
  }

  public DeleteGeneralGarnish(garnishId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/DeleteGeneralGarnish', 
      {
        garnishId: garnishId
      });
  }


  public GetFranchiseGeneralGarnishGroups(franchiseId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'GarnishGroup/GetFranchiseGeneralGarnishGroups?franchiseId=' + franchiseId);
  }

  public GetItemGeneralGarnishGroups(itemId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'GarnishGroup/GetItemGeneralGarnishGroups?itemId=' + itemId);
  }

  
  public DeleteGeneralGarnishGroup(ggGroupId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/DeleteGeneralGarnishGroup', 
      {
        ggGroupId: ggGroupId
      });
  }

  public CopyGeneralGarnishGroup(ggGroupId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/CopyGeneralGarnishGroup', 
      {
        ggGroupId: ggGroupId
      });
  }

  public UpdateGeneralGarnishGroup(ggGroup): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/UpdateGeneralGarnishGroup', ggGroup );
  }

  public UpdateGeneralGarnishGroupItems(ggGroup): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/UpdateGeneralGarnishGroupItems', ggGroup );
  }

  public CreateGeneralGarnishGroup(ggGroup): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/CreateGeneralGarnishGroup', ggGroup );
  }


  public DeleteGarnish(garnishId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Item/DeleteGarnish', 
      {
        garnishId: garnishId
      });
  }
  
  public deleteGeneralGarnishFromGroup(garnishId, generalGarnishGroupId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/DeleteGeneralGarnishFromGroup', 
      {
        garnishId: garnishId,
        generalGarnishGroupId:generalGarnishGroupId
      });
  }


  public UpdateGarnishes(garnishes: any[]): Observable<any> {
    var fd = new FormData();
    var i = 0;    
    garnishes.forEach(function (gar, index) {
      if (gar.Name != undefined && gar.Name.length > 0) {

        if (gar.Id != undefined) fd.append("Garnishes[" + index + "].Id", gar.Id);
        if (gar.FranchiseId != undefined) fd.append("Garnishes[" + index + "].FranchiseId", gar.FranchiseId);
        if (gar.FranchiseId != undefined) fd.append("Garnishes[" + index + "].GeneralGarnishGroupId", gar.GeneralGarnishGroupId);
        fd.append("Garnishes[" + index + "].Name", gar.Name);
        fd.append("Garnishes[" + index + "].Price", gar.Price);
        fd.append("Garnishes[" + index + "].CatalogNumber", gar.CatalogNumber);
        fd.append("Garnishes[" + index + "].ItemId", gar.ItemId);
        fd.append("Garnishes[" + index + "].ImageUrl", gar.ImageUrl);
        fd.append("Garnishes[" + index + "].Image", gar.Image);
        fd.append("Garnishes[" + index + "].Order", gar.Order);
       // fd.append("Garnishes[" + index + "].GarnishGroupId", gar.GarnishGroupId);
       // fd.append("Garnishes[" + index + "].GeneralGarnishGroupId", gar.GeneralGarnishGroupId);
        fd.append("Garnishes[" + index + "].IsItem", gar.IsItem);        
        if (gar.MaxAmount) fd.append("Garnishes[" + index + "].MaxAmount", gar.MaxAmount);
        i++;  
      }
    });

    return this.http.post<any>(environment.serverUrl +
      'GarnishGroup/UpdateGarnishes', fd );
  }


}
