export class GarnishAppModel {

  public Id: number;
  public ItemId: number;
  public FranchiseId: number;
  public GarnishGroupId: number;
  public GeneralGarnishGroupId: number;
  public Order: number;
  public Price: number;
  public Name: string;
 // public ContentType: number;
  //public ContectTypeId: number;
  public CatalogNumber: string;
  public ImageUrl: string;
  public ImageGuid: string;
  public Image: any;
  public MaxAmount: number;
  public IsItem: boolean; 
  public Active: boolean;
  public Updated: boolean; 
public edit:boolean;
 
//BranchAvailability: []
 
 
 
}
