//// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  country: 'il',
  language: 'he',
  serverUrl: "https://admin-test.bite-tech.co.il/"
   //serverUrl: "52.53.240.158/"
//serverUrl: "https://admin.bitetechus.com/"
//serverUrl: "https://localhost:44393/"
//serverUrl: "https://admin.bite-tech.co.il/"
 //bite-2c9b3
 
};
