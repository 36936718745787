import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
//import {TranslationsService} from "../../shared/translations/translations.service";

@Injectable()
export class ComboService {

  constructor(private http: HttpClient) {}

  public getCombos(franchiseId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'Combo/GetCombos?franchiseId=' + franchiseId);
  }

  public getCombo(comboId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'Combo/GetCombo?id=' + comboId);
  }

  public deleteCombo(comboId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Combo/DeleteCombo', 
      {
        id: comboId
      });
  }

  public createCombo(combo): Observable<any> {
    var fd = new FormData();
    if (combo.StartDate == undefined) combo.StartDate=new Date(2021,1,10);
    if (combo.StartTime == undefined) combo.StartTime=new Date(2021,1,10);
    if (combo.EndDate == undefined) combo.EndDate= new Date(2030,10,5);
    if (combo.EndTime == undefined) combo.EndTime= new Date(2030,10,5);
    fd.append("Name", combo.Name);
    fd.append("Order", combo.Order);
    fd.append("CatalogNumber", combo.CatalogNumber);
    fd.append("ImageFile", combo.ImageFile);
    fd.append("FranchiseId", combo.FranchiseId);
    fd.append("Description", combo.Description);
    combo.ItemCombos.forEach(function(itemCombo, i){
        fd.append("ItemCombos[" + i + "].Name", itemCombo.Name);
        fd.append("ItemCombos[" + i + "].Quantity", itemCombo.Quantity);
        fd.append("ItemCombos[" + i + "].MaxGarnishes", itemCombo.MaxGarnishes);
        itemCombo.Items.forEach(function(item, j){
              fd.append("ItemCombos[" + i + "].Items[" + j + "].Id", item.Id);
        })
    });
    combo.PizzaCombos.forEach(function(pizza, index){
        fd.append("PizzaCombos[" + index + "].PizzaId", pizza.PizzaId);
        fd.append("PizzaCombos[" + index + "].Quantity", pizza.Quantity);
        fd.append("PizzaCombos[" + index + "].MaxToppings", pizza.MaxToppings);
        fd.append("PizzaCombos[" + index + "].SizeId", pizza.SizeId);
    });

    fd.append("Price", combo.Price);
    fd.append("StartDate", combo.StartDate);
    fd.append("EndDate", combo.EndDate);
    fd.append("StartTime", combo.StartTime);
    fd.append("EndTime", combo.EndTime);
    return this.http.post<any>(environment.serverUrl +
      'Combo/CreateCombo', fd );
  }

  public updateCombo(combo): Observable<any> {
   
    var fd = new FormData();
      fd.append("Id", combo.Id);
      fd.append("CatalogNumber", combo.CatalogNumber);
      fd.append("Order", combo.Order);
      fd.append("Name", combo.Name);
      fd.append("ImageFile", combo.ImageFile);
      fd.append("FranchiseId", combo.FranchiseId);
      fd.append("Description", combo.Description);

      combo.ItemCombos.forEach(function(itemCombo, i){
          fd.append("ItemCombos[" + i + "].Id", itemCombo.Id);
          fd.append("ItemCombos[" + i + "].Name", itemCombo.Name);
          fd.append("ItemCombos[" + i + "].Quantity", itemCombo.Quantity);
          fd.append("ItemCombos[" + i + "].MaxGarnishes", itemCombo.MaxGarnishes);
          itemCombo.Items.forEach(function(item, j){
                fd.append("ItemCombos[" + i + "].Items[" + j + "].Id", item.Id);
          })
      });

      combo.PizzaCombos.forEach(function(pizza, index){
          fd.append("PizzaCombos[" + index + "].Id", pizza.Id);
          fd.append("PizzaCombos[" + index + "].PizzaId", pizza.PizzaId);
          fd.append("PizzaCombos[" + index + "].Quantity", pizza.Quantity);
          fd.append("PizzaCombos[" + index + "].MaxToppings", pizza.MaxToppings);
          fd.append("PizzaCombos[" + index + "].SizeId", pizza.SizeId);
      });

      fd.append("Price", combo.Price);
      fd.append("StartDate", combo.StartDate);
      fd.append("EndDate", combo.EndDate);
      fd.append("StartTime", combo.StartTime);
      fd.append("EndTime", combo.EndTime);

    return this.http.post<any>(environment.serverUrl +
      'Combo/UpdateCombo', fd );
  }
 

}
