import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
//import {TranslationsService} from "../../shared/translations/translations.service";

@Injectable()
export class PizzaService {

  constructor(private http: HttpClient) {}

  /// Pizza ///
  public getAllPizzas(franchiseId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'Pizza/GetPizzas?franchiseId=' + franchiseId);
  }

  public getPizza(pizzaId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'Pizza/GetPizza?id=' + pizzaId);
  }

  public createPizza(pizza): Observable<any> {
    var fd = new FormData();
      fd.append("Name", pizza.Name);
      fd.append("FranchiseId", pizza.FranchiseId);
      fd.append("Description", pizza.Description);
      fd.append("SizeId", pizza.SizeId);
      fd.append("Price", pizza.Price);
      fd.append("ImageFile", pizza.ImageFile);
      fd.append("Information", pizza.Information);
      fd.append("Order", pizza.Order);
      fd.append("CatalogNumber", pizza.CatalogNumber);      
      pizza.PizzaPrices.forEach(function(price, index) {
        fd.append("PizzaPrices[" + index + "].Price", price.Price);
        fd.append("PizzaPrices[" + index + "].CatalogNumber", price.CatalogNumber);
        fd.append("PizzaPrices[" + index + "].PizzaSizeId", price.PizzaSizeId);
      });

      pizza.PizzaToppings.forEach(function(top, index) {
        fd.append("PizzaToppings[" + index + "].ToppingId", top.ToppingId);
      });
    return this.http.post<any>(environment.serverUrl +
      'Pizza/CreatePizza', fd );
  }

  public updatePizza(pizza): Observable<any> {
    var fd = new FormData();
    fd.append("Id", pizza.Id);
    fd.append("Name", pizza.Name);
    fd.append("FranchiseId", pizza.FranchiseId);
    fd.append("Description", pizza.Description);
    fd.append("SizeId", pizza.SizeId);
    fd.append("Price", pizza.Price);
    fd.append("ImageFile", pizza.ImageFile);
    fd.append("Information", pizza.Information);
    fd.append("Order", pizza.Order);
    fd.append("CatalogNumber", pizza.CatalogNumber);
    pizza.PizzaPrices.forEach(function(price, index) {
      fd.append("PizzaPrices[" + index + "].Price", price.Price);
      fd.append("PizzaPrices[" + index + "].Id", price.Id);
      fd.append("PizzaPrices[" + index + "].PizzaSizeId", price.PizzaSizeId);
      fd.append("PizzaPrices[" + index + "].PizzaId", price.PizzaId);
      fd.append("PizzaPrices[" + index + "].CatalogNumber", price.CatalogNumber);        
    });

    pizza.PizzaToppings.forEach(function(top, index) {
      if (top.Id != undefined) fd.append("PizzaToppings[" + index + "].Id", top.Id);
      fd.append("PizzaToppings[" + index + "].ToppingId", top.ToppingId);
    });
    return this.http.post<any>(environment.serverUrl +
      'Pizza/UpdatePizza', fd );
  }

  public deletePizza(pizzaId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Pizza/DeletePizza', 
      {
        id: pizzaId
      });
  }

  ////////////////////////////


  /// Toppings ////

  public getAllToppings(franchiseId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'Topping/GetToppings?franchiseId=' + franchiseId);
  }

  public createTopping(topping): Observable<any> {
    var fd = new FormData();
    fd.append("Name", topping.Name);
    fd.append("FranchiseId", topping.FranchiseId);
    fd.append("Order", topping.Order);
    fd.append("CatalogNumber", topping.CatalogNumber);      
    if (topping.ImageFile != undefined) fd.append("ImageFile", topping.ImageFile);
    if (topping.QuarterPizzaImageFile != undefined) fd.append("QuarterPizzaImageFile", topping.QuarterPizzaImageFile);
    topping.ToppingPrices.forEach(function(price, index) {
      fd.append("ToppingPrices[" + index + "].Price", price.Price);
      fd.append("ToppingPrices[" + index + "].QuarterPrice", price.QuarterPrice);
      fd.append("ToppingPrices[" + index + "].HalfPrice", price.HalfPrice);
      fd.append("ToppingPrices[" + index + "].ThreeQuarterPrice", price.ThreeQuarterPrice);
      fd.append("ToppingPrices[" + index + "].PizzaSizeId", price.PizzaSizeId);
    });
    return this.http.post<any>(environment.serverUrl +
      'Topping/CreateTopping', fd );
  }

  public updateTopping(topping): Observable<any> {
    var fd = new FormData();
      fd.append("Id", topping.Id);
      fd.append("Name", topping.Name);
      fd.append("FranchiseId", topping.FranchiseId);
      fd.append("Order", topping.Order);
      fd.append("CatalogNumber", topping.CatalogNumber);
      if (topping.ImageFile != undefined) fd.append("ImageFile", topping.ImageFile);
      if (topping.QuarterPizzaImageFile != undefined) fd.append("QuarterPizzaImageFile", topping.QuarterPizzaImageFile);

      topping.ToppingPrices.forEach(function(price, index) {
        fd.append("ToppingPrices[" + index + "].Price", price.Price);
        fd.append("ToppingPrices[" + index + "].QuarterPrice", price.QuarterPrice);
        fd.append("ToppingPrices[" + index + "].HalfPrice", price.HalfPrice);
        fd.append("ToppingPrices[" + index + "].ThreeQuarterPrice", price.ThreeQuarterPrice);
        fd.append("ToppingPrices[" + index + "].Id", price.Id);
        fd.append("ToppingPrices[" + index + "].PizzaSizeId", price.PizzaSizeId);
        fd.append("ToppingPrices[" + index + "].ToppingId", price.ToppingId);
      });
    return this.http.post<any>(environment.serverUrl +
      'Topping/UpdateTopping', fd );
  }

  public deleteTopping(toppingId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Topping/DeleteTopping', 
      {
        id: toppingId
      });
  }
///////////////////////////////////

/// Pizza Size ////

  public getAllPizzaSizes(franchiseId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 
      'PizzaSize/GetPizzaSizes?franchiseId=' + franchiseId);
  }

  public createPizzaSize(pizzaSize): Observable<any> {   
    return this.http.post<any>(environment.serverUrl +
      'PizzaSize/CreatePizzaSize', 
      {
        webModel: pizzaSize
      });
  }

  public updatePizzaSize(pizzaSize): Observable<any> {   
    return this.http.post<any>(environment.serverUrl +
      'PizzaSize/UpdatePizzaSize',
      {
        webModel: pizzaSize
      });
  }

  public saveDefaultSize(pizzaSize, franchiseId): Observable<any> {   
    console.log("saveDefaultSize",pizzaSize);
    return this.http.post<any>(environment.serverUrl +
      'PizzaSize/SaveDefaultSize',
      {
        pizzaSizeId: pizzaSize,
          franchiseId: franchiseId
      });
  }

  public deletePizzaSize(pizzaSizeId): Observable<any> {   
    return this.http.post<any>(environment.serverUrl +
      'PizzaSize/DeletePizzaSize',
      {
        id: pizzaSizeId
      });
  }
/////////////


}
