import { GarnishAppModel } from "./garnish-app.model";
import { GarnishGroupAppModel } from "./garnish-group-app.model";

export class ItemAppModel {

  public Id: number;
  public Name: string;
  public CategoryId: number;
  public CatalogNumber: string;
  public Garnishes: GarnishAppModel[];
  public GarnishGroups: GarnishGroupAppModel[];
  public GeneralGarnishGroups: GarnishGroupAppModel[];
  public IsTakeAway: boolean;
  //public IsEatInBranch: boolean;
  public IsDelivery: boolean;
  public IsSit: boolean;
 
  public Image: any;
  public ImageUrl: string;
  public Information: string;
  public IsShowInKioskEndOrder: boolean;
public MealUpgrade: boolean;
  public Order: number;
  public Price: number;

  public ContentType: number;
  public ContectTypeId: number;

}
